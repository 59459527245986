/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/integratesearch/v4/integratesearch.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * - DSPAD_COMPONENT_TYPE_CATALOG_ADVERTISEMENT: 일반  - DSPAD_COMPONENT_TYPE_CAROUSEL_ADVERTISEMENT: 캐러셀 광고
 * @export
 * @enum {string}
 */

export const V1DSPADComponentType = {
    UNSPECIFIED: 'DSPAD_COMPONENT_TYPE_UNSPECIFIED',
    CATALOG_ADVERTISEMENT: 'DSPAD_COMPONENT_TYPE_CATALOG_ADVERTISEMENT',
    CAROUSEL_ADVERTISEMENT: 'DSPAD_COMPONENT_TYPE_CAROUSEL_ADVERTISEMENT'
} as const;

export type V1DSPADComponentType = typeof V1DSPADComponentType[keyof typeof V1DSPADComponentType];



